import { useClientTranslation } from "@rimo/i18n/useClientTranslation";
import { styled } from "styled-components";
import CheckIcon from "@mui/icons-material/Check";
import { MouseEventHandler } from "react";

export interface PlanItemProps {
  name: string;
  price: number;
  simplifiedFeatures: string[];
  isCurrentPlan?: boolean;
  selected?: boolean | undefined;
  onClickPlan?: MouseEventHandler<HTMLDivElement> | undefined;
}

export const PlanItem: React.FC<PlanItemProps> = ({
  name,
  price,
  simplifiedFeatures,
  isCurrentPlan = false,
  selected = false,
  onClickPlan,
}) => {
  const { customT } = useClientTranslation();
  return (
    <PlanItemContainer $active={selected} $isCurrentPlan={isCurrentPlan} onClick={onClickPlan}>
      <PlanDetails>
        <PlanName>{customT(name)}</PlanName>
        <PlanFeatures>{simplifiedFeatures.join(", ")}</PlanFeatures>
      </PlanDetails>

      {!isCurrentPlan && (
        <>
          <Pricing>
            <Price>${price} /month</Price>
            <PricingLabel>Billed ${price * 12} annually</PricingLabel>
          </Pricing>
          <RadioButton $active={selected}>{<StyledCheckIcon />}</RadioButton>
        </>
      )}
    </PlanItemContainer>
  );
};

const PlanItemContainer = styled.div<{ $active: boolean; $isCurrentPlan: boolean }>`
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${(p) => p.theme.vars.palette.border};
  cursor: pointer;
  margin: 0.5rem 0;

  ${(p) =>
    p.$active &&
    `
    background: #f14c0019;
    border: 1px solid ${p.theme.vars.palette.primary.main};

  `}

  ${(p) =>
    p.$isCurrentPlan &&
    `
		background: ${p.theme.vars.palette.background.light};
		border: 1px solid ${p.theme.vars.palette.background.light};
		cursor: default;
	`}
`;

const PlanDetails = styled.div``;

const PlanName = styled.div`
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;

const PlanFeatures = styled.div`
  font-size: 0.9rem;
`;

const Pricing = styled.div`
  margin-left: auto;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;

const Price = styled.div`
  font-weight: 600;
  margin-bottom: 0.3rem;
`;

const PricingLabel = styled.div`
  font-size: 0.9rem;
`;

const RadioButton = styled.div<{ $active: boolean }>`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.vars.palette.border};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.vars.palette.border};

  ${(p) =>
    p.$active &&
    `
    border: 1px solid ${p.theme.vars.palette.primary.main};
		background: ${p.theme.vars.palette.primary.main};
    color: #fff;
  `}
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 1rem;
`;
