import { useLocaleContext } from "../contexts/LocaleProvider";

const defaultOption: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
};
export const useIntlDateTime = () => {
  const { locale } = useLocaleContext();
  return (_date: string | Date, option?: Intl.DateTimeFormatOptions) => {
    const date = typeof _date === "string" ? new Date(_date) : _date;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return Intl.DateTimeFormat(locale, { ...defaultOption, ...option, timeZone })
      .format(date)
      .split(", ")
      .join(" ");
  };
};
