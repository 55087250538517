import { FC, MouseEventHandler } from "react";
import styled from "styled-components";

interface ToggleButtonProps {
  isActive: boolean;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
  size?: number;
}

export const ToggleButton: FC<ToggleButtonProps> = ({ isActive, onClick, size = 1 }) => {
  return (
    <ToggleContainer $active={isActive} $size={size} onClick={onClick}>
      <ToggleCircle $active={isActive} $size={size} />
    </ToggleContainer>
  );
};

const ToggleContainer = styled.div<{ $active: boolean; $size: number }>`
  width: ${(p) => `${p.$size * 2}rem`};
  height: ${(p) => `${p.$size}rem`};
  border-radius: ${(p) => `${p.$size}rem`};
  background: #f14c0019;
  position: relative;
  cursor: pointer;
  background: ${(p) => p.theme.vars.palette.background.light};
  border: 1px solid ${(p) => p.theme.vars.palette.border};
  transition: 0.3s;

  ${(p) =>
    p.$active &&
    `
    border: 1px solid #f14c00;
    background: #f14c00;
  `}
`;

const ToggleCircle = styled.div<{ $active: boolean; $size: number }>`
  --inner-padding: ${(p) => `calc(0.1rem * ${p.$size})`};
  width: ${(p) => `${p.$size * 0.7}rem`};
  height: ${(p) => `${p.$size * 0.7}rem`};
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(var(--inner-padding), -50%);
  border: 1px solid ${(p) => p.theme.vars.palette.border};
  transition: 0.3s ease-in-out;

  ${(p) =>
    p.$active &&
    `
    border: 1px solid #fff;
    transform: translate(calc(${p.$size * 2}rem - 100% - var(--inner-padding) - 1px), -50%);
  `}.
`;
