import type { KeyboardEventHandler, MouseEventHandler } from "react";
import { styled } from "styled-components";
import { BillingCycle } from "@rimo/frontend/types/subscription";

export const PricingPaymentGroup = ({
  value,
  onToggle,
}: {
  value: BillingCycle;
  onToggle: (value: BillingCycle) => void;
}) => {
  const handleKeydown =
    (newValue: BillingCycle): KeyboardEventHandler<HTMLDivElement> =>
    (e) => {
      if (e.key.toUpperCase() === "ENTER") {
        onToggle(newValue);
      }
    };

  const handleClick =
    (newValue: BillingCycle): MouseEventHandler<HTMLDivElement> =>
    () => {
      onToggle(newValue);
    };

  return (
    <Container>
      <Item $selected={value === "monthly"} onClick={handleClick("monthly")} onKeyDown={handleKeydown("monthly")}>
        Monthly
      </Item>
      <Item $selected={value === "yearly"} onClick={handleClick("yearly")} onKeyDown={handleKeydown("yearly")}>
        Yearly
      </Item>
    </Container>
  );
};

const Container = styled.div`
  width: inherit;
  display: flex;
  padding: 4px;
  border-radius: 12px;
  background-color: ${(p) => p.theme.vars.palette.background.light};
  gap: 4px;
`;

const Item = styled.div.attrs({ role: "button", tabIndex: 0 })<{
  $selected: boolean;
}>`
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  ${(p) => {
    if (p.$selected) {
      return {
        color: p.theme.vars.palette.text.primary,
        background: p.theme.vars.palette.background.default,
      };
    } else {
      return {
        color: "rgba(102, 102, 102, 1)",
      };
    }
  }}
`;
