import { BillingCycle, Grade, InterviewUsage } from "@rimo/frontend/types/subscription";
import { client } from "../api";

export type PlanStatus = "none" | "current" | "upgrade" | "downgrade";
export type PlanProps = {
  [key in BillingCycle]: { [grade in Grade]: PlanStatus };
};

export const getPlanProps = (currentGrade: Grade, currentCycle: BillingCycle): PlanProps => {
  const defaultPlanProps: PlanProps = {
    monthly: { free: "current", basic: "upgrade", pro: "upgrade", unlimited: "none" },
    yearly: { free: "current", basic: "upgrade", pro: "upgrade", unlimited: "none" },
  };

  // アップグレード可能な状態を設定
  if (currentGrade === "basic" && currentCycle === "monthly") {
    defaultPlanProps.monthly.basic = "upgrade"; // basic mo -> pro mo
    defaultPlanProps.yearly.basic = "upgrade"; // basic mo -> pro ye
    defaultPlanProps.yearly.pro = "upgrade"; // basic mo -> basic ye
  } else if (currentGrade === "basic" && currentCycle === "yearly") {
    defaultPlanProps.monthly.pro = "upgrade"; // basic ye -> pro mo
    defaultPlanProps.yearly.pro = "upgrade"; // basic ye -> pro ye
  } else if (currentGrade === "pro" && currentCycle === "monthly") {
    defaultPlanProps.yearly.pro = "upgrade"; // pro mo -> pro ye
  }

  // ダウングレード可能な状態を設定
  if (currentGrade === "pro") {
    defaultPlanProps.monthly.basic = "downgrade"; // pro mo/ye -> basic mo
    defaultPlanProps.yearly.basic = "downgrade"; // pro mo/ye -> basic ye
    if (currentCycle === "yearly") {
      defaultPlanProps.monthly.pro = "downgrade"; // pro ye -> pro mo
    }
  } else if (currentGrade === "basic" && currentCycle === "yearly") {
    defaultPlanProps.monthly.basic = "downgrade"; // basic ye -> basic mo
  }

  if (currentGrade && currentCycle) {
    defaultPlanProps.monthly.free = "none";
    defaultPlanProps.yearly.free = "none";
    defaultPlanProps[currentCycle][currentGrade] = "current";
  }

  return defaultPlanProps;
};

export const getNextBillingDate = (startDate: Date, billingCycle: BillingCycle): Date => {
  let year = startDate.getFullYear();
  let month = startDate.getMonth();
  let day = startDate.getDate();

  if (billingCycle === "monthly") {
    month += 1;
    if (month > 11) {
      month = 0;
      year += 1;
    }
  } else if (billingCycle === "yearly") {
    // うるう年の2月29日の扱い
    if (month === 1 && day === 29) {
      // 2月29日
      // 次の年がうるう年でなければ、2月28日にする
      if (!((year + 1) % 4 === 0 && ((year + 1) % 100 !== 0 || (year + 1) % 400 === 0))) {
        day = 28;
      }
    }

    const nextBillingDate = new Date(year + 1, month, day);
    return nextBillingDate;
  }

  return new Date(year, month, day);
};

export const redirectCheckoutSession = async (serviceId: string, cycle: BillingCycle, grade: Grade) => {
  try {
    const { data } = await client.post<{ url: string }>("/subscriptions/checkout", {
      product_name: `aieditor_${grade}_${cycle}`,
      service_id: serviceId,
    });

    window.location.href = data.url;
  } catch (e) {
    console.error("Failed to get checkout session url:", e);
    return null;
  }
};

export const redirectBillingPortalSession = async (returnUrl: string, type?: string, subscriptionId?: string) => {
  try {
    const { data } = await client.post<{ url: string }>("/subscriptions/billing_portal", {
      return_url: returnUrl,
      type,
      subscription_id: subscriptionId,
    });

    window.location.href = data.url;
  } catch (e) {
    console.error("Failed to get billing portal session url:", e);
    return null;
  }
};

export const getGradeString = (grade: Grade): string => {
  return grade.charAt(0).toUpperCase() + grade.slice(1);
};

export const getGradeFromProductName = (productName: string): Grade => {
  return productName ? (productName.split("_")[1] as Grade) : "free";
};

export const IsUsageLimited = (usage: InterviewUsage | undefined | null): boolean => {
  return ((usage && getGradeFromProductName(usage.product_name) !== "unlimited") || !usage) ?? false;
};
